import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.1_next@15.0.3_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-_wsduw5236dkcx3n57e4oh4tnom/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/.pnpm/next-themes@0.4.3_react-dom@19.0.0-rc-28668d39-20241023_react@19.0.0-rc-28668d39-20241023__re_jzprl5zuk2urzxgjs6pppin6pi/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-28668d39-20241023_g2lqzxtgmzs4rj6hwhhmcnuvpi/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/vercel/path0/node_modules/.pnpm/nuqs@2.1.2_@remix-run+react@2.13.1_react-dom@19.0.0-rc-28668d39-20241023_react@19.0.0-rc-2866_g5ugausdm7vjcitky3xaml7lxi/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalProviders"] */ "/vercel/path0/src/app/global-providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.css");
